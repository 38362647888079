<template>
  <div class="mail-setting-form">
    <main>
      <MformSkeleton :loading="loading">
        <el-form
          label-position="left"
          label-width="120px"
          :model="formData"
          size="medium"
          style="width: 534px"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="邮件标题：" prop="email_title">
            <div class="title-input-wrap">
              <div class="hint-wrap">
                <el-tooltip
                  class="item"
                  effect="light"
                  content="内置变量说明:{from_name}初始为站点名称，转赠后则为转赠用户邮箱；{coupon_code}为优惠券号码"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <el-input v-model="formData.email_title"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="发件人昵称：" prop="email_name">
            <el-input v-model="formData.email_name"></el-input>
          </el-form-item>
          <el-form-item label="模板按钮颜色：" prop="button">
            <m-color-select :current-value="formData.button" @change="changeColor" />
          </el-form-item>
        </el-form>
      </MformSkeleton>
    </main>
    <footer>
      <MFormFooter @save="save" :loading="saveLoading" />
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';

import MFormFooter from '@/components/formFooter/FormFooter';
import MformSkeleton from '@/components/formSkeleton/FormSkeleton';
import MColorSelect from '@/components/colorSelect/ColorSelect';
import { saveData, getMailDetail } from '../../api/mailSetting';
import { useStore } from 'vuex';

export default {
  components: { MFormFooter, MformSkeleton, MColorSelect },
  setup() {
    const { state } = useStore();
    const loading = ref(false);
    const formData = ref({
      email_title: `You've received a Gift Card for {站点}`,
      email_name: 'service',
      button: '',
    });
    const form = ref();
    const saveLoading = ref(false);

    const setFormData = async () => {
      const res = await getMailDetail({ store_id: state.storeId }, loading);
      if (res.status) {
        formData.value.email_title = res.data.list.email_title;
        formData.value.email_name = res.data.list.email_name;
        formData.value.button = res.data.list.button;
      }
    };

    const changeColor = cur => {
      formData.value.button = cur.color;
    };

    const save = async () => {
      form.value.validate(async valid => {
        if (!valid) return;

        const { status } = await saveData(formData.value, saveLoading);
        if (status) {
          ElMessage.success('保存成功');
        }
      });
    };

    return {
      loading,
      setFormData,
      formData,
      save,
      form,
      saveLoading,
      changeColor,
      rules: {
        email_title: [
          { required: true, whitespace: true, message: '请输入邮件标题', trigger: 'blur' },
        ],
        email_name: [
          { required: true, whitespace: true, message: '请输入发件人昵称', trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    this.setFormData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.mail-setting-form {
  main {
    background-color: $formBg;
    padding: 20px;
    padding-bottom: 0.1px;
    .title-input-wrap {
      position: relative;
      .hint-wrap {
        position: absolute;
        left: -32px;
        display: flex;
        align-items: center;
        height: 100%;
        .el-icon-question {
          font-size: 16px;
          color: #666;
          cursor: pointer;
        }
      }
    }
    .el-form-item {
      margin-bottom: 24px;
    }
  }
  footer {
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    margin-top: 30px;
  }
}
</style>
