<template>
  <div class="m-form-footer">
    <el-button size="medium" type="primary" @click="save" :loading="loading">{{ text }}</el-button>
  </div>
</template>

<script lang="ts">
import { PropType } from '@vue/runtime-core';

export default {
  props: {
    text: {
      type: String as PropType<string>,
      default: '保存',
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['save'],
  setup(props, { emit }) {
    const save = () => {
      emit('save');
    };
    return {
      save,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool.scss';
.m-form-footer {
  height: 60px;
  background-color: $mediumGray;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .el-button {
    min-width: 120px;
  }
}
</style>
