
import { PropType } from '@vue/runtime-core';

export default {
  props: {
    text: {
      type: String as PropType<string>,
      default: '保存',
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['save'],
  setup(props, { emit }) {
    const save = () => {
      emit('save');
    };
    return {
      save,
    };
  },
};
