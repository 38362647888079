<template>
  <div class="m-color-select" @click.stop>
    <el-popover
      placement="bottom"
      :width="selectWidth"
      trigger="manual"
      v-model:visible="selectFocus"
    >
      <template #reference>
        <div class="m-color-select-bar" :style="selectBarStyle" @click="setFocus" ref="selectWrap">
          <span
            class="select-color-preview"
            :style="{
              backgroundColor: selectVal.color,
              border:
                selectVal.color.toLowerCase() === '#fff' ||
                selectVal.color.toLowerCase() === '#ffffff'
                  ? '1px solid #ccc'
                  : 'none',
            }"
          ></span>
          {{ selectVal.name || '请选择颜色' }}
          <input type="hidden" :value="selectVal.color" />

          <i
            class="el-icon-arrow-up"
            :style="{
              transform: selectFocus ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
            }"
          ></i>
        </div>
      </template>
      <ul class="colors-list">
        <li
          v-for="item in colors"
          :key="item.color"
          @click="onSelect(item)"
          :class="JSON.stringify(item) === JSON.stringify(selectVal) ? 'active' : ''"
        >
          <span
            class="color-preview"
            :style="{
              backgroundColor: item.color,
              border:
                item.color.toLowerCase() === '#fff' || item.color.toLowerCase() === '#ffffff'
                  ? '1px solid #ccc'
                  : 'none',
            }"
          ></span>
          <span class="color-text">{{ item.name }}</span>
        </li>
      </ul>
    </el-popover>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, PropType, ref, watchEffect } from 'vue';

interface ColorsItem {
  color: string;
  name: string;
}

export default defineComponent({
  props: {
    colors: {
      type: Array as PropType<ColorsItem[]>,
      default: () => [
        { color: '#fff', name: '默认' },
        { color: '#ff2452', name: '红色' },
        { color: '#ff8542', name: '橙色' },
        { color: '#089b32', name: '绿色' },
        { color: '#428fff', name: '蓝色' },
        { color: '#c942ff', name: '紫色' },
        { color: '#fb6cb1', name: '粉色' },
        { color: '#333333', name: '黑色' },
        { color: '#fbd540', name: '黄色' },
        { color: '#fe9a04', name: '橙黄色' },
        { color: '#fa039c', name: '洋红色' },
        { color: '#6b473c', name: '浅褐色' },
        { color: '#843900', name: '褐色' },
        { color: '#666400', name: '棕绿色' },
        { color: '#b2d235', name: '浅绿色' },
        { color: '#029834', name: '深绿色' },
        { color: '#2c6dff', name: '浅蓝色' },
        { color: '#d27fff', name: '浅紫色' },
        { color: '#b600ff', name: '深紫色' },
        { color: '#009ad6', name: '青色' },
        { color: '#00ae9d', name: '青绿色' },
        { color: '#102b6a', name: '青蓝色' },
      ],
    },
    currentValue: {
      type: String as PropType<string>,
    },
  },
  setup(props, { emit }) {
    const selectVal = ref();

    watchEffect(() => {
      selectVal.value = props.currentValue
        ? props.colors.find(item => item.color === props.currentValue)
        : props.colors[0];
    });

    const selectFocus = ref(false);
    const selectBarStyle = computed(() => {
      return {
        color: selectVal.value ? '#333' : '#aaa',
        borderColor: selectFocus.value ? '#666798' : '#dcdfe6',
      };
    });
    // 设置 select 框是否获取焦点
    const setFocus = () => {
      selectFocus.value = !selectFocus.value;
    };

    // 选择事件
    const onSelect = item => {
      const prev = { ...selectVal.value };
      emit('beforeChange', item);

      selectVal.value = item;
      selectFocus.value = false;

      emit('change', item, prev);
    };

    // 全局绑定click事件，控制下拉框失去焦点
    const setGlobalBlur = () => {
      document.addEventListener('click', function () {
        selectFocus.value = false;
      });
    };

    const selectWidth = ref(0);
    const selectWrap = ref();
    // 设置 select 框的width
    const setSelectWidth = () => {
      nextTick(() => {
        selectWidth.value = selectWrap.value.offsetWidth - 24;
      });
    };

    return {
      selectVal,
      selectFocus,
      selectBarStyle,
      setFocus,
      setGlobalBlur,
      selectWidth,
      setSelectWidth,
      selectWrap,
      onSelect,
    };
  },
  mounted() {
    this.setGlobalBlur();
    this.setSelectWidth();
  },
});
</script>

<style lang="scss" scoped>
.m-color-select {
  user-select: none;
  .m-color-select-bar {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    line-height: 38px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.14s linear;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;

    .select-color-preview {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border-radius: 3px;
      box-sizing: border-box;
    }
    .el-icon-arrow-up {
      position: absolute;
      right: 10px;
      color: #c0c4cc;
      font-size: 14px;
      transition: transform 0.3s;
      transform: rotateZ(180deg);
      cursor: pointer;
    }
  }
}

.colors-list {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    width: 100px;
    align-items: center;
    margin: 4px 5px;
    border-radius: 3px;
    padding: 6px 10px;
    cursor: pointer;
    transition: all 0.4s linear;

    &.active {
      background-color: #f5f7fa;
    }
    &:hover {
      background: #f5f7fa;
    }
    .color-preview {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }
}
</style>
